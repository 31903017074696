<template>
  <div id="login">
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          shaped
          top
          v-model="snackbarStatus"
          :timeout="snackbarTimeout"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <v-container fluid>
      <v-row no-gutters>
        <v-col sm="12" md="4" lg="4" xl="4" offset-md="4" offset-sm="0">
          <v-card color="#FAEDF0" class="pa-5 rounded-15" elevation="20">
            <v-row justify="center">
              <img
                  class="mt-3 mb-6"
                  width="50px"
                  height="50px"
                  src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Android_O_Preview_Logo.png"
                  alt=""
              />
            </v-row>
            <v-form class="mt-3" v-model="form">
              <v-text-field
                  v-model="email"
                  label="ایمیل"
                  align="end"
                  :rules="emailRules"
                  color="#292C6D"
                  outlined
              ></v-text-field>
              <v-text-field
                  v-model="password"
                  label="رمز عبور"
                  outlined
                  :rules="passwordRules"
                  color="#292C6D"
                  type="password"
              ></v-text-field>
              <v-checkbox
                  v-model="checkbox"
                  label="مرا بخاطر بسپار"
                  color="#292C6D"
              ></v-checkbox>
              <v-row>
                <v-col cols="6">
                  <v-btn :disabled="!form" class="mr-1 white--text" color="#292C6D" @click="login">ورود</v-btn>
                </v-col>
                <v-col cols="6">
                  <a class="mr-1 register_link mb-2" @click="navigateToRegisterPage"
                  >عضو نیستید؟ ثبت نام کنید
                  </a>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Login",
  data: () => {
    return {
      form: false,
      email: "",
      password: "",
      checkbox: false,
      emailRules: [
        (v) => !!v || "پر کردن این فیلد الزامی است",
        (v) => /.+@.+\..+/.test(v) || "ایمیل وارد شده صحیح نیست",
      ],
      passwordRules: [
        (v) => !!v || "پر کردن این فیلد الزامی است",
      ],
    };
  },
  methods: {
    async login(e) {
      window.axios.get('sanctum/csrf-cookie').then(() => {
        window.axios.post('user/login', {
              email: this.email,
              password: this.password,
        })
            .then((res) => {
              this.$store.commit('login', res.data.data)
              localStorage.setItem('token', res.data.data.token);
              let redirectTo = this.$route.query.redirect
              if (redirectTo) {
                window.location.replace(
                    redirectTo
                );
              } else {
                window.location.replace('/panel');
              }
            }).catch((err) => {
              if (err.response.status === 422) {
                this.showValidationErrors(err.response.data.errors)
              } else if(err.response.status === 401) {
                this.openSnackbar(err.response.data.message, '#EC255A')
              }
        })
      })
      e.preventDefault();
    },
    navigateToRegisterPage(){
      this.$router.push('/register').then();
    }
  },
};
</script>
<style scoped>
#login {
  background: linear-gradient(
      to bottom,
      rgb(41, 44, 109),
      rgba(41, 44, 109, 0.85)
  );
  height: 100vh;
  display: flex;
  align-items: center;
}
.theme--light.v-sheet--outlined {
  border-radius: 5px !important;
}
.mt-150 {
  margin-top: 150px;
}
.v-text-field--outlined > .v-input__control > .v-input__slot{
  min-height: 45px !important;
}
.v-text-field--outlined .v-label {
    top: 13px !important;
}
.register_link{
  font-size: 10px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: block;
  float: left;
  margin-top: 10px;
}
</style>